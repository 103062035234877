.CurrencyRatesStatus-container {
  text-align: center;
}

.CurrencyRatesStatus {
  font-size: 0.5em;
  padding: 1em;
  margin: 0 auto;
  width: 80%;
  border-radius: 0.4em 0.4em 0 0;
  background: rgba(255, 255, 255, 0.1);
}

.CurrencyRatesStatus-date {
  display: inline-block;
  background: rgba(0, 0, 0, 0.1);
  margin: 0 0.25em;
  padding: 0 0.25em;
}
