.CurrencyHistory-container {
  text-align: center;
}

.CurrencyHistory {
  font-size: 0.5em;
  padding: 1em;
  margin: 0 auto;
  width: 80%;
  border-radius: 0 0 0.4em 0.4em;
  background: rgba(255, 255, 255, 0.1);
}

.CurrencyHistory-graph-container {
  width: 100%;
  min-height: 100px;
  margin: 1em 0;
  border-bottom: solid 1px rgba(255, 255, 255, 0.2);
}

.CurrencyHistory-graph-currency {
  display: inline-block;
  margin: 0 0.2em;
}

.CurrencyHistory-graph-day-range {
  display: inline-block;
  margin-left: 1em;
}

.CurrencyHistory-drawer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s;
  background: rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0.5em 1em rgba(0, 0, 0, 0.2);
}

.CurrencyHistory-open .CurrencyHistory-drawer {
  max-height: 10000px;
  transition: max-height 0.8s;
}

.CurrencyHistory-table {
  margin: 1em;
}

.CurrencyHistory-table thead tr {
  border-bottom: solid 1px white;
}
.CurrencyHistory-table thead td {
  background: rgba(0, 0, 0, 0.2);
}

.CurrencyHistory-table tr {
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
}
.CurrencyHistory-table tr:hover {
  background: rgba(0, 0, 0, 0.1);
}
.CurrencyHistory-table td {
  padding: 0.2em 1em;
}

.CurrencyHistory-toggle-drawer-button {
  font-size: 1.4em;
  padding: 0.2em 1em;
  margin: 1em;
  border-radius: 0.4em;
  border: 0;
  background-color: #7AC1FF;
  color: #fff;
  cursor: pointer;
}
.CurrencyHistory-toggle-drawer-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
