.CurrencyConverter {
	display: flex;
	flex-direction: column;
}
.CurrencyConverter-header {
  margin: 0 0 1em 0;
}
  .CurrencyConverter-header h1 {
    margin: 0;
  }

.CurrencyConverter-subtitle {
	color: #61DAFB;
	font-size: 0.7em;

}

.CurrencyConverter-logo {
  height: 1em;
  pointer-events: none;
  vertical-align: middle;
}

 @media (prefers-reduced-motion: no-preference) {
  .CurrencyConverter-logo {
    animation: CurrencyConverter-logo-spin infinite 60s linear;
  }
}

@keyframes CurrencyConverter-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
