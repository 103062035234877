.CurrencyConverterForm {
	display: flex;
	padding: 1em;
	box-shadow: 0 0.2em 1em rgba(0, 0, 0, 0.4);
	border-radius: 0.4em;
	justify-content: space-evenly;
	flex-grow: 0;
}

@media (max-width:500px) {

  .CurrencyConverterForm { flex-direction: column; }

}
  .CurrencyConverterForm > div {
    align-self: center;
  }
