body {
  padding: 0;
  margin: 0;
  background-color: black;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  min-height: 100vmin;
}

.App-footer {
  font-size: 0.4em;
  margin: 3em 0;
}
  .App-footer span {
    display: inline-block;
  }

.App-credits {
  margin: 1em;
}

.App-author {
  margin: 0 0.5em;
}

a {
  color: #61dafb;
}

