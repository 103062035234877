.AmountInput-container {
	margin: 0.5em;
}

.AmountInput {
	font-size: inherit;
	padding: 0.5em;
	width: 8em;
	border-radius: 0.2em;

}
